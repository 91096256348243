import React from 'react'
import './resume.css'   
import cv from '../../assets/cvromain.png'
export default function ResumeComponent() {
    
  return (
    <div className='container resume__container'>
        <div className='resume__cv__modal'>
          <a href={cv} download className='resume__cv__link'><img src={cv} alt='curricalum vitae ' className='resume__cv__img'/></a>
        </div>
    </div>

  )
}
