import React from 'react'
import './banner.css'
import Flashbanner from './Flashbanner'



export default function Banner() {
  return (
    <div className='banner'>
      <div className='flash-banner hello-banner'>
        <Flashbanner text="Hello There" size="420px" duration='40s' weight="100"/>
      </div>      
        <section className='container-banner'>
            <h6 className='title banner__title title__hello'>— Hello There</h6>
            <h1 className='title banner__title title__name'>I'm Romain Danizel.</h1>
            <h1 className='title banner__title title__intro'>I turn ideas into apps and websites.</h1>
        </section>
        <div className='flash-banner im-banner'>
          <Flashbanner text="I'm Romain" size="230px" duration='25s' weight="100"/>
        </div>
    </div>
  )
}
