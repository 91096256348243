import React from 'react'
import Navbar from '../components/Navbar'
import ResumeComponent from '../components/Resume/Resume'

export default function Resume() {
  return (
    <>
        <Navbar/>
        <ResumeComponent/>
    </>
  )
}
