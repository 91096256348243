import './App.css'
import Home from './page/Home';
import Resume from './page/Resume';
import {BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/resume' loader={'lazy'} element={<Resume/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
