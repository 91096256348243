import React from 'react'
import {Link} from "react-router-dom"
import './navbar.css'
export default function Navbar() {
  return (
      <nav className='nav'>
          <ul className='container-nav'>
              <li className='list-nav project__nav'><Link to="/" className='list-nav__link link'>Projects</Link></li>
              <li className='list-nav resume__nav'><Link to="/resume" className='list-nav__link link'>Resume</Link></li>
              <li className='list-nav about__nav'><Link to="/" className='list-nav__link link'>About me</Link></li>
              <li className='list-nav'><a href="mailto:romain.danizel.pro@gmail.com" className='list-nav__link link'>Contact</a></li>
          </ul>
      </nav>
  )
}
